import React, { useRef, useState } from "react";
// import NavBar from "components/NavBar/NavBar";

const Success = ({ link }) => {
  const [isCopied, setIsCopied] = useState(false);
  const textAreaRef = useRef(null);

  const copyToClipboard = () => {
    const text = textAreaRef.current.innerText;

    navigator.clipboard.writeText(text).then(
      () => {
        console.log("Text copied to clipboard");
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1000)
      },
      (err) => {
        console.error("Could not copy text: ", err);
        setIsCopied(false);
      }
    );
  };

  return (
    <>
      {/* <NavBar /> */}

      <div
        className="col-12 aos-init aos-animate"
        data-aos="fade-up"
        data-aos-delay="600"
      >
        <div className="col-md why-choice-options option-three mb-0 text-center">
          <h4  style={{
              fontSize: "19px",
            }}>API Created Successfully!</h4>
          <p
            style={{
              fontSize: "15px",
            }}
            className="text-center"
          >
            <b>API Link:</b> <span ref={textAreaRef}>{link}</span>
          </p>
          {!isCopied ? (
            <a
              className="miwlo-btn-simple"
              href="#?"
              style={{
                fontSize: "15px",
              }}
              onClick={copyToClipboard}
            >
              Copy Link
            </a>
          ) : (
            <span style={{
              fontSize: "15px",
            }}>Copied!</span>
          )}
        </div>
        <br />
        <div
          className="miwlo-pricing-plan-features text-center"
          style={{
            textAlign: "center",
            fontSize: "16px",
          }}
        >
          <b>Features Coming at Full Launch: &nbsp;</b>
          <span>
            Various request types, database connectivity, validation,
            authentication, and middleware checks.
          </span>
        </div>
        <br />
        <div
          style={{
            textAlign: "center",
            fontSize: "18px",
          }}
        >
          <b>Thank You for Trying Our Quick Demo!</b>
          <br />
          <br />
          <p>Do you think this platform would be valuable to you?</p>
          <p >
            <a style={{
            color: "green",
            marginRight: "10px"
          }} target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSfhoCxuAexGwKxCRu_JomX1pfU2XA8qNXjbvw-1Dd-4CmWFZg/viewform">[Yes]</a>
          
          <a style={{
            color: "red"
          }} target="_blank" rel="noreferrer" href="https://docs.google.com/forms/d/e/1FAIpQLSe6wJt3L94FqMqHfc-jE5mkNskUSyW2hNJ4XUGj8FOKWziYMA/viewform">[No]</a>
          
          </p>
          
          
          <p>Your feedback means a lot to us.</p>
        </div>
      </div>
    </>
  );
};

export default Success;
