import React from "react";

const Main = () => {
  // const [selectedFile, setSelectedFile] = useState(null);
  // const [title, setTitle] = useState("");
  // const [error, setError] = useState("");
  // const [name, setName] = useState("");

  // const handleFileChange = (event) => {
  //   setError("");
  //   const file = event.target.files[0];
  //   setSelectedFile(file);
  // };

  // const handleInputTitleChange = (event) => {
  //   event.persist();
  //   setError("");
  //   setTitle(event.target.value);
  // };

  // const uploadData = useMutation(
  //   "addDataFile",
  //   async (data) => {
  //     const response = await uploadApi(data);
  //     return response;
  //   },
  //   {
  //     onSuccess: (data) => {
  //       window.location.href = "exploratory/bdi/" + data.data.id;
  //     },
  //     onError: () => {
  //       setError("Error: Automator failed to start. Try Again!");
  //     },
  //   }
  // );

  // const handleUpload = () => {
  //   setError("");
  //   if (!title.trim()) {
  //     return setError("Title is required!");
  //   }
  //   if (!selectedFile) {
  //     return setError("Please select a file!");
  //   }

  //   const fileType = selectedFile.type;

  //   if (fileType !== "text/csv") {
  //     return setError("Oops! Only a .CSV file is allowed!");
  //   }
  //   const formData = new FormData();
  //   formData.append("file", selectedFile);
  //   formData.append("email", getUserInfo()?.email);
  //   formData.append("title", title.trim());

  //   uploadData.mutate(formData);
  // };

  // useEffect(() => {
  //   setName(getUserInfo()?.email?.split("@")[0]);
  // }, []);

  return (
    <>
      {/* <div className="mobile-author-actions"></div> */}
      {/* <NavBar /> */}

      <div
        className="miwlo-saas-landing-banner-wrap"
        style={{
          marginTop: "0px",
          paddingTop: "70px",
        }}
      >
        <div className="miwlo-saas-landing-banner-bg">
          <img
            src="./assets/images/banner/saas-banner-bg.jpg"
            alt="Background"
            style={{
              height: "45vh",
            }}
          />
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md text-center">
              <div className="miwlo-saas-landing-banner-text">
                <h3>
                  Accelerate Your Development <br />
                  Create APIs with Ease
                </h3>
                <p >
                  Create, manage, and deploy API endpoints in <b>minutes</b>{" "}
                  instead of <b>hours</b>.
                </p>
                <div >
                  <a className="miwlo-btn-pill btn-black radius" href="/start">
                    Get Started
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="miwlo-why-choose-wrap"
        style={{
          padding: "100px 0px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="miwlo-why-choose-text text-center"
              >
                <p className="section-subheading">Don't Miss It</p>
                <h3 className="section-heading">Why Manta Matters</h3>
              </div>
              <div></div>
            </div>
          </div>
          <div className="row text-center">
            <div
              className="col-12 col-md-6 col-lg aos-init aos-animate"
            >
              <div className="why-choice-options option-one">
                <h4>Multiple Request Types</h4>
                <p>Easily create GET, POST, PUT, DELETE, and more.</p>
              </div>
            </div>
            <div
              className="col-12 col-md-6 col-lg aos-init aos-animate"
            >
              <div className="why-choice-options option-two">
                <h4>Database Connectivity</h4>
                <p>Easily connect with your databases.</p>
              </div>
            </div>
            <div
              className="col-12 col-md-6 col-lg aos-init aos-animate"
            >
              <div className="why-choice-options option-three">
                <h4>Validation and Authentication</h4>
                <p>Secure your APIs and ensure data accuracy.</p>
              </div>
            </div>
            <div
              className="col-12 col-md-6 col-lg aos-init aos-animate"
            >
              <div className="why-choice-options option-four">
                <h4>Middleware Checks</h4>
                <p>
                  Implement custom logic and security measures effortlessly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="miwlo-subscribe-newsletter-wrap is-full-width">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-12 col-12">
              <div
                class="miwlo-subscribe-text aos-init aos-animate text-center"
              >
                <h3>See MantaHQ in action!</h3>
                <p>
                  Whether you're a startup, a freelancer, or an enterprise, our
                  tool is here to SPEED UP your development process.
                </p>
                <br />
                <div>
                  <a className="miwlo-btn-pill btn-black radius" href="/start">
                    Get Started
                  </a>
                </div>
                <br />
                <br />
                Copyright @ 2024
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
