import React, { useState, useEffect } from "react";
import { useMutation } from "react-query";
// import Logo from "../../assets/img/logo-dp.png";

import { loginApi } from "api/auth";

import { loginValidationSchema } from "utils/validationSchema";
import { TOKEN_NAME } from "utils/constants";

const Login = () => {
  const [inputs, setInputs] = useState({
    usernameOrEmail: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setErrors] = useState({});

  useEffect(() => {
    if (document) {
      document.title = "MantaHQ - Login";
    }
  }, []);

  const loginUser = useMutation(
    "loginUser",
    async (data) => {
      const response = await loginApi(data);
      return response;
    },
    {
      onSuccess: (data) => {
        localStorage.setItem(TOKEN_NAME, data.data.token);
        window.location.href = "/welcome";
      },
      onError: (error) => {
        setErrorMessage(error?.response?.data?.message);
      },
    }
  );

  const handleInputChange = (event) => {
    event.persist();
    setErrors({});
    setInputs((preState) => ({
      ...preState,
      [event.target.name]: event.target.value,
    }));
    setErrorMessage(null);
  };

  const handleSubmit = () => {
    setErrorMessage("");
    loginValidationSchema
      .validate(inputs, { abortEarly: false })
      .then(() => {
        const { usernameOrEmail, password } = inputs;
        loginUser.mutate({ usernameOrEmail, password });
        setErrors("");
      })
      .catch((err) => {
        let errList = {};
        err.inner.forEach((e) => {
          errList = { ...errList, [e.path]: e.message };
        });
        setErrors(errList);
      });
  };

  return (
    <>
      <div
        className="miwlo-why-choose-wrap miwlo-why-choose-v2"
        style={{
          marginTop: "100px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="miwlo-why-choose-text text-center">
                <div
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <a href="/">
                    <img src="./assets/images/favicon.png" alt="logo" />
                  </a>
                </div>
                <p className="section-subheading">Welcome Back</p>
                <h3
                  className="section-heading"
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Enter an email address and password
                </h3>
                <div style={styles.errorMessageBox}>{errorMessage}</div>
                <div
                  style={{
                    maxWidth: "300px",
                    margin: "0 auto",
                  }}
                >
                  <input
                    type="email"
                    id="email"
                    placeholder="Your Email"
                    style={{
                      borderRadius: "50px",
                      height: "50px",
                      marginTop: "20px",
                      padding: "20px"
                    }}
                    name="usernameOrEmail"
                    value={inputs.usernameOrEmail}
                    onChange={handleInputChange}
                  />
                  <span style={styles.errorBox}>{error.usernameOrEmail}</span>
                  <input
                    type="password"
                    id="password"
                    placeholder="Password"
                    style={{
                      borderRadius: "50px",
                      height: "50px",
                      marginTop: "20px",
                      padding: "20px"
                    }}
                    name="password"
                    value={inputs.password}
                    onChange={handleInputChange}
                  />
                  <span style={styles.errorBox}>{error.password}</span>
                  <button
                    style={{
                      borderRadius: "50px",
                      height: "50px",
                      marginTop: "20px",
                      background: "#231f20",
                    }}
                    onClick={loginUser.isLoading ? () => {} : handleSubmit}
                    disabled={loginUser.isLoading}
                  >
                    {loginUser.isLoading ? "Loggin in..." : "Login"}
                  </button>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    marginTop: "30px",
                  }}
                >
                  <p>
                    Don't have an account? <a href="/start">Create Account</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  errorBox: {
    color: "red",
    marginBottom: "15px",
    fontSize: "13px",
  },
  errorMessageBox: {
    color: "red",
    textAlign: "center",
    marginBottom: "20px",
  },
  successBox: {
    color: "green",
    textAlign: "center",
    marginBottom: "20px",
  },
};

export default Login;
