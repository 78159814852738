import axios from "../config/axios";
import axios_node from "../config/axios_node";

export const getAllTasksApi = async () => {
  const request = await axios.get("/task/getall");
  return request;
};

export const getUserTasksApi = async () => {
  const request = await axios.get("/task/getusertasks");
  return request;
};

export const getSingleTasksApi = async (taskSlug) => {
  const request = await axios.get(`/task/getsingle/${taskSlug}`);
  return request;
};

export const getSingleUserTaskApi = async (userTaskId) => {
  const request = await axios.get(`/task/usertask/${userTaskId}`);
  return request;
};


export const getTaskReviewsApi = async (userTaskId) => {
  const request = await axios.get(`/task/reviews/${userTaskId}`);
  return request;
};


export const addUserTasksApi = async (inputs) => {
  const request = await axios.post("/task/create/usertask", inputs);
  return request;
};

export const submitTaskApi = async (inputs) => {
  const request = await axios.post("/task/submit", inputs);
  return request;
};

// API APP APIS
export const addUserApi = async (inputs) => {
  const request = await axios_node.post("/task/createendpoint", inputs);
  return request;
};

export const getUserApis = async () => {
  const request = await axios_node.get("/task/userapis");
  return request;
};
