import React, { useState, useEffect } from "react";
import { getUserApis } from "api/tasks";
import { useQuery } from "react-query";
import NavBar from "components/NavBar/NavBar";
import { getUserInfo } from "utils/authHandler";

// import Logo from "../../assets/img/logo-dp.png";

const Launch = () => {
  const username = getUserInfo()?.username;
  const workspace = getUserInfo()?.workspace;
  const [error, setError] = useState("");
  const [apiList, setApiList] = useState(null);

  const {
    refetch: getUserAllApisFetch,
    isLoading: isLoadingApis,
    // isError,
  } = useQuery(
    "getUserApis",
    async () => {
      setError("");
      const response = await getUserApis();
      return response;
    },
    {
      onSuccess: (data) => {
        return setApiList(data?.data?.result);
      },
      onError: (err) => {
        if (err?.response?.data?.statusCode === 404) {
          return setApiList([]);
        }
        setError("Oops! Failed to load your apis.");
      },
    }
  );

  useEffect(() => {
    getUserAllApisFetch();
  }, [getUserAllApisFetch]);

  return (
    <>
      <NavBar />
      <div
        className="miwlo-why-choose-wrap miwlo-why-choose-v2"
        style={{
          marginTop: "100px",
        }}
      >
        <div className="container" data-aos="fade-up" data-aos-delay="400">
          <div className="row">
            <div className="col-12">
              <div
                className="col-xs aos-init aos-animate"
                style={{
                  maxWidth: "400px",
                  margin: "auto",
                }}
              >
                <div
                  className="miwlo-pricing-plan-table table-two"
                  style={{
                    textAlign: "center",
                    marginBottom: "20px",
                    padding: "20px",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  <img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/33/Cartoon_space_rocket.png/1069px-Cartoon_space_rocket.png"
                    width="50px"
                    alt="launch"
                  />
                </div>
              </div>
              <div
                className="miwlo-why-choose-text text-center"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <p className="section-subheading">
                  Platform Launching Fully Soon!
                </p>
                <h3
                  className="section-heading"
                  style={{
                    fontSize: "17px",
                  }}
                >
                  While we're getting things ready, try a brief demo of our
                  tool.
                </h3>
                <div
                  style={{
                    maxWidth: "350px",
                    margin: "0 auto",
                  }}
                >
                  {/* <input type="email" id="email" placeholder="Your Email" style={{
                    borderRadius: "50px",
                    height: "50px",
                    marginTop: "20px"
                  }}/> */}
                  <button
                    style={{
                      borderRadius: "50px",
                      height: "50px",
                      marginTop: "20px",
                      background: "#231f20",
                    }}
                    onClick={() => {
                      window.location.href = "./create-endpoint";
                    }}
                  >
                    Create Your First API
                  </button>
                </div>
              </div>
              <div className="comments-area blog-post-comments">
                <h3 className="comments-title">Your Workspace APIs</h3>
                <h5
                  className="comments-title"
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Workspace name: {workspace}
                </h5>
                <div
                  style={{
                    color: "red",
                  }}
                >
                  {error}
                </div>
                {isLoadingApis ? (
                  "Loading all your apis..."
                ) : (
                 (apiList.length < 1 || apiList === null) && !isLoadingApis  ? "No API created yet!" : <ul className="comment-list">
                    {apiList?.map((api, index) => {
                      const { requesttype, name } = api;
                      return (
                        <li
                          className="comment-item"
                          key={index + "-api-list-" + requesttype + name}
                        >
                          <div className="card">
                            <div className="row g-0">
                              <div className="col-sm-9">
                                <div className="card-body">
                                  <p className="card-text">
                                    <small
                                      className="text-muted"
                                      style={{
                                        color: "#48381b",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Request Type:{" "}
                                      <span
                                        style={{
                                          textTransform: "uppercase",
                                        }}
                                      >
                                        {requesttype}
                                      </span>   [ :/{name} ]
                                    </small>
                                  </p>
                                  <p
                                    style={{
                                      fontSize: "15px",
                                    }}
                                  >
                                    <b>API Link:</b>{" "}
                                    {`https://manta-hq.onrender.com/api/active/${username}/${workspace}/${name}`}
                                  </p>

                                  {/* <div>
                              <a
                                href="#?"
                                style={{
                                  color: "#231f20",
                                  fontSize: "15px",
                                }}
                              >
                                (edit)
                              </a>
                              <a
                                href="#?"
                                style={{
                                  marginLeft: "15px",
                                  color: "#231f20",
                                  fontSize: "15px",
                                }}
                              >
                                (delete)
                              </a>
                              <a
                                href="#?"
                                style={{
                                  marginLeft: "15px",
                                  color: "#231f20",
                                  fontSize: "15px",
                                }}
                              >
                                (view api doc)
                              </a>
                            </div> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const styles = {
//   errorBox: {
//     color: "red",
//     textAlign: "left",
//     marginBottom: "20px",
//   },
//   errorMessageBox: {
//     color: "red",
//     textAlign: "center",
//     marginBottom: "20px",
//   },
//   successBox: {
//     color: "green",
//     textAlign: "center",
//     marginBottom: "20px",
//   },
// };

export default Launch;
