import React, { useState } from "react";
import NavBar from "components/NavBar/NavBar";
import { useMutation } from "react-query";
import { createEndpointValidationSchema } from "utils/validationSchema";
import { getUserInfo } from "utils/authHandler";
import { addUserApi } from "api/tasks";
import Success from "./Success";

const CreateEndpoint = () => {
  const userWorkSpaceName = getUserInfo()?.workspace;
  const username = getUserInfo()?.username;
  const [inputs, setInputs] = useState({
    name: "",
    type: "get",
    returnType: "json",
    jsonvalue: "",
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [errors, setErrors] = useState({});
  const [isSuccess, setIsSuccess] = useState(false);
  const handleInputChange = (event) => {
    event.persist();
    setInputs((preState) => ({
      ...preState,
      [event.target.name]: event.target.value.trim(),
    }));
    setErrorMessage(null);
    setErrors({});
  };

  const createUserApi = useMutation(
    "createUserApi",
    async (data) => {
      const response = await addUserApi(data);
      return response;
    },
    {
      onSuccess: (data) => {
        setIsSuccess(true);
      },
      onError: (error) => {
        setIsSuccess(false);
        if (error?.response?.data?.statusCode === 409) {
          setErrorMessage(error?.response?.data?.message || "Failed to create the endpoint!");
        } else {
          setErrorMessage("Failed to create the endpoint!");
        }
      },
    }
  );

  const isValidJson = (jsonString) => {
    try {
      // Attempt to parse the JSON string
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      console.log(e, "error here");
      // If an error is thrown, it's not valid JSON
      return false;
    }
  };
  const handleSubmit = () => {
    setErrorMessage(null);
    setErrors({});
    createEndpointValidationSchema
      .validate(inputs, { abortEarly: false })
      .then(() => {
        const { name, type, returnType, jsonvalue } = inputs;

        if (!isValidJson(jsonvalue)) {
          return setErrors({ jsonvalue: "Invalid JSON Value" });
        }
        createUserApi.mutate({
          name,
          workspaceid: getUserInfo()?.workspaceid,
          requesttype: type,
          returntype: returnType,
          returnvalue: JSON.stringify(jsonvalue),
          username,
          workspacename: userWorkSpaceName
        });
        setErrors({});
      })
      .catch((err) => {
        let errList = {};
        err.inner.forEach((e) => {
          errList = { ...errList, [e.path]: e.message };
        });
        setErrors(errList);
      });
  };

  return (
    <>
      <NavBar />
      <div
        className="miwlo-why-choose-wrap miwlo-why-choose-v2"
        style={{
          marginTop: "85px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 ">
              {isSuccess && (
                <Success
                  link={`https://manta-hq.onrender.com/api/active/${username}/${userWorkSpaceName}/${inputs.name}`}
                />
              )}
              {/* <h3
                className="section-heading text-center"
                style={{
                  fontSize: "17px",
                }}
              >
                <b>
                  <a
                    href="./welcome"
                    style={{
                      textDecoration: "none",
                    }}
                  >{`< Back Home >`}</a>
                </b>
              </h3> */}
              <hr />
              {!isSuccess && (
                <>
                  <h3
                    className="section-heading text-center"
                    style={{
                      fontSize: "17px",
                    }}
                  >
                    <b>API Creator</b>
                  </h3>
                  <p
                    className="text-center"
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    Your workspace name: <b>{userWorkSpaceName}</b> (
                    <a href="#?">edit - coming soon!</a>)
                  </p>
                  <section
                    className="support_help_area sec_pad"
                    style={{
                      margin: "0% 5%",
                    }}
                  >
                    <div
                      className="container"
                      style={{
                        marginTop: "5%",
                      }}
                    >
                      <div style={styles.errorMessageBox}>{errorMessage}</div>
                      <div
                        className="form-group text_box"
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        <label className="f_p text_c f_400">Name*:</label>
                        <input
                          type="text"
                          placeholder="Name"
                          style={{
                            border: "1px solid black",
                            height: "40px",
                          }}
                          name="name"
                          value={inputs.name}
                          onChange={handleInputChange}
                        />
                        <span style={styles.errorBox}>{errors.name}</span>
                        <div>
                          endpoint url:{" "}
                          <span
                            style={{
                              fontSize: "15px",
                              display: "inline-block",
                            }}
                          >
                            <b>{`https://manta-hq.onrender.com/api/active/${username}/${userWorkSpaceName}/${
                              inputs.name || `{name here}`
                            }`}</b>
                          </span>
                        </div>
                      </div>
                      <div
                        className="form-group col-md-12 text_box"
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        <label className="f_p text_c f_400">
                          Request Type*:
                        </label>
                        <select
                          className="selectpickers nice-select w-100 text_box"
                          style={{
                            border: "1px solid black",
                            height: "40px",
                          }}
                          name="type"
                          value={inputs.type}
                          onChange={handleInputChange}
                        >
                          <option value="get">GET</option>
                          <option value="post">POST</option>
                          <option value="delete">DELETE</option>
                          <option value="update">UPDATE</option>
                        </select>
                        {inputs.type !== "get" && (
                          <div
                            className="text-center"
                            style={{
                              fontSize: "15px",
                              fontWeight: "bold",
                            }}
                          >
                            <br />
                            We know how this is important to you!
                            <br />
                            *Request types; POST, UPDATE & DELETE will be
                            available when we're fully live!*
                          </div>
                        )}
                      </div>
                      <div
                        className="form-group col-md-12"
                        style={{
                          marginBottom: "20px",
                        }}
                      >
                        <label className="f_p text_c f_400">Return*:</label>
                        <select
                          className="selectpickers nice-select w-100 text_box"
                          style={{
                            border: "1px solid black",
                            background: "none !important",
                            height: "40px",
                          }}
                          name="returnType"
                          value={inputs.returnType}
                          onChange={handleInputChange}
                        >
                          <option value="json">Specified JSON object</option>
                          <option value="table">From Database (Table)</option>
                          {/* <option value="post">POST</option> */}
                        </select>
                      </div>
                      {inputs.returnType === "json" && (
                        <div className="form-group col-lg-12 apply_form">
                          <label className="f_p text_c f_400">
                            Return Value*: <br /><span style={{
                              fontSize: "14px"
                            }}>Add your Custom JSON Object here:</span>
                          </label>
                          <br />
                          <textarea
                            name="jsonvalue"
                            id="message"
                            cols="30"
                            rows="5"
                            placeholder="Add your Custom JSON Object here"
                            style={{
                              border: "1px solid black",
                            }}
                            onChange={handleInputChange}
                            value={inputs.jsonvalue}
                          ></textarea>
                          <pre
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            <div>
                              <b>Return value preview here:</b>
                            </div>
                            <br />
                            {`${inputs.jsonvalue}`}
                          </pre>
                          <span style={styles.errorBox}>
                            {errors.jsonvalue}
                          </span>
                          {errors.jsonvalue && (
                            <pre
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              <div>
                                <b>Sample of what a JSON looks like:</b>
                              </div>
                              <br />
                              {`{ "message": "success", "status": "200" }`}
                            </pre>
                          )}
                        </div>
                      )}
                      {inputs.returnType === "table" && (
                        <div
                          className="text-center"
                          style={{
                            fontSize: "15px",
                            fontWeight: "bold",
                          }}
                        >
                          We know how this is important to you!
                          <br />
                          *Connecting to and returning data from a database
                          table coming when we're fully live!*
                        </div>
                      )}
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {/* <input type="email" id="email" placeholder="Your Email" style={{
                    borderRadius: "50px",
                    height: "50px",
                    marginTop: "20px"
                  }}/> */}
                      {inputs.type === "get" &&
                        inputs.returnType === "json" && (
                          <button
                            style={{
                              borderRadius: "50px",
                              height: "50px",
                              marginTop: "20px",
                              background: "#231f20",
                            }}
                            onClick={handleSubmit}
                            disabled={createUserApi.isLoading}
                          >
                            {createUserApi.isLoading
                              ? "Creating API..."
                              : "Create API"}
                          </button>
                        )}
                    </div>
                  </section>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  errorBox: {
    color: "red",
    marginBottom: "15px",
    fontSize: "13px",
  },
  errorMessageBox: {
    color: "red",
    textAlign: "center",
    marginBottom: "20px",
    fontSize: "15px",
  },
  successBox: {
    color: "green",
    textAlign: "center",
    marginBottom: "20px",
  },
};

export default CreateEndpoint;
