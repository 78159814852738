import React, { useEffect } from "react";
// import BurgerIcon from "../../assets/img/svg/bars.svg";
import clearLocalStorage from "utils/clearData";
// import Logo from "../../assets/img/logo-dp.png";
import { isAuthenticated, getUserInfo } from "utils/authHandler";

const NavBar = () => {
  // const [email, setEmail] = useState("");
  const handleLogOut = () => {
    clearLocalStorage();
    window.location.href = "/login";
  };

  useEffect(() => {
    if (!isAuthenticated()) {
      return handleLogOut();
    }
    // setEmail(getUserInfo()?.email);
  }, []);

  return (
    <>
      <header
        className="header-area-desktop miwlo-white-bg miwlo-header-black"
        style={{
          marginTop: "15px",
          display: "block",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 5px",
                }}
              >
                <div
                  style={{
                    width: "30%",
                  }}
                >
                  <a className="navbar-brand" href="/welcome">
                    <img
                      src="./assets/images/logo-black.png"
                      alt="Miwlo"
                      style={{
                        width: "35px",
                      }}
                    />
                  </a>
                  <a href="/welcome" style={{
                    marginLeft: "2px",
                    fontSize: "14px",
                    textDecoration: "none"
                  }}>
                    Home
                  </a>
                </div>
                <div
                  style={{
                    width: "70%",
                    textAlign: "right",
                    fontSize: "13px",
                  }}
                >
                  <img
                    src="https://i.pinimg.com/736x/0e/9b/76/0e9b7605a89c5b556eb3c3331874cbac.jpg"
                    width="40px"
                    alt="user"
                  />
                  <span>{getUserInfo()?.username}</span>
                  <a
                    className="radius"
                    href="#?"
                    style={{
                      padding: "2px 5px",
                      textDecoration: "none",
                      position: "relative",
                      top: "-1px",
                    }}
                    onClick={handleLogOut}
                  >
                   (Logout)
                  </a>
                </div>
              </div>
              {/* <nav className="navbar navbar-expand-md miwlo-initial-navbar">
                <a className="navbar-brand" href="index.html">
                  <img
                    src="./assets/images/logo-black.png"
                    alt="Miwlo"
                    style={{
                      width: "40px",
                    }}
                  />
                </a>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                  style={{
                    display: "block",
                  }}
                >
                  <ul className="navbar-nav ms-auto">
                    <li>
                    <img
                      src="https://cdn-icons-png.freepik.com/512/5087/5087607.png"
                      width="30px"
                    />
                    Hi {getUserInfo()?.username}
                    </li>
                  </ul>

                  <ul className="button-wrapper ml-5">
                    <li>
                      <a
                        className="radius"
                        href="#"
                        style={{
                          padding: "2px 14px",
                          textDecoration: "none"
                        }}
                        onClick={handleLogOut}
                      >
                        
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </nav> */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default NavBar;
