import React, { useState, useEffect } from "react";
import { SignUpValidationSchema } from "utils/validationSchema";
import { signupApi } from "api/auth";
import { TOKEN_NAME } from "utils/constants";
import { useMutation } from "react-query";
// import Logo from "../../assets/img/logo-dp.png";

const CreateAccount = () => {
  // const [inputType, setInputType] = useState("password");
  const [inputs, setInputs] = useState({
    email: "",
    password: "",
    workspace: "",
  });

  const [errorMessage, setErrorMessage] = useState(null);
  const [error, setError] = useState({});

  useEffect(() => {
    if (document) {
      document.title = "MantaHQ - Get Started";
    }
  }, []);

  const signUserUp = useMutation(
    "createAccount",
    async (data) => {
      const response = await signupApi(data);
      return response;
    },
    {
      onSuccess: (data) => {
        localStorage.setItem(TOKEN_NAME, data.data.token);
        window.location.href = "/welcome";
      },
      onError: (error) => {
        if (error?.response?.data?.statusCode === 409) {
          setErrorMessage(error?.response?.data?.message);
        } else {
          setErrorMessage("Failed to create account!");
        }
      },
    }
  );

  const handleInputChange = (event) => {
    event.persist();
    setInputs((preState) => ({
      ...preState,
      [event.target.name]: event.target.value,
    }));
    setErrorMessage(null);
  };

  const handleSubmit = () => {
    setErrorMessage(null);
    SignUpValidationSchema.validate(inputs, { abortEarly: false })
      .then(() => {
        const { email, password, workspace } = inputs;

        signUserUp.mutate({
          email,
          password,
          workspace,
        });
        setError("");
      })
      .catch((err) => {
        let errList = {};
        err.inner &&
          err.inner.forEach((e) => {
            errList = { ...errList, [e.path]: e.message };
          });
        setError(errList);
      });
  };

  // const handleShowPassword = () => {
  //   setInputType(inputType === "password" ? "text" : "password");
  // };

  return (
    <>
      <div
        className="miwlo-why-choose-wrap miwlo-why-choose-v2"
        style={{
          marginTop: "100px",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="miwlo-why-choose-text text-center">
                <div
                  style={{
                    marginBottom: "25px",
                  }}
                >
                  <a href="/">
                    <img src="./assets/images/favicon.png" alt="logo" />
                  </a>
                </div>
                <p className="section-subheading">Welcome, let's get started</p>
                <h3
                  className="section-heading"
                  style={{
                    fontSize: "15px",
                  }}
                >
                  Enter an email address, password & workspace name
                </h3>
                <div style={styles.errorMessageBox}>{errorMessage}</div>
                <div
                  style={{
                    maxWidth: "300px",
                    margin: "0 auto",
                  }}
                >
                  <input
                    type="email"
                    id="email"
                    placeholder="Your Email"
                    style={{
                      borderRadius: "50px",
                      height: "50px",
                      marginTop: "20px",
                      padding: "20px"
                    }}
                    name="email"
                    value={inputs.email}
                    onChange={handleInputChange}
                  />
                  <span style={styles.errorBox}>{error.email}</span>
                  <input
                    type="password"
                    id="password"
                    placeholder="Password"
                    style={{
                      borderRadius: "50px",
                      height: "50px",
                      marginTop: "20px",
                      padding: "20px"
                    }}
                    name="password"
                    value={inputs.password}
                    onChange={handleInputChange}
                  />
                  <span style={styles.errorBox}>{error.password}</span>

                  <input
                    type="text"
                    id="workspace"
                    placeholder="Workspace name"
                    style={{
                      borderRadius: "50px",
                      height: "50px",
                      marginTop: "20px",
                      padding: "20px"
                    }}
                    name="workspace"
                    value={inputs.workspace}
                    onChange={handleInputChange}
                  />
                  <span style={styles.errorBox}>{error.workspace}</span>

                  <button
                    style={{
                      borderRadius: "50px",
                      height: "50px",
                      marginTop: "20px",
                      background: "#231f20",
                    }}
                    onClick={handleSubmit}
                  >
                    Continue
                  </button>
                </div>
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "15px",
                    marginTop: "30px",
                  }}
                >
                  <p>
                    Already have an account? <a href="/login">Login</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const styles = {
  errorBox: {
    color: "red",
    marginBottom: "15px",
    fontSize: "13px",
  },
  errorMessageBox: {
    color: "red",
    textAlign: "center",
    marginBottom: "20px",
  },
  successBox: {
    color: "green",
    textAlign: "center",
    marginBottom: "20px",
  },
};

export default CreateAccount;
