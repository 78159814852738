import { Route } from "react-router-dom";
import Main from "../pages/main";
import Login from "../pages/login";
// import Signup from "../pages/signup";
import CreateAccount from "pages/createaccount";
import CreateEndpoint from "pages/createendpoint/CreateEndpoint";
import Launch from "pages/launch/Launch";
// import PasswordReset from "../pages/passwordreset";

export const landingRoutes = [
  {
    type: Route,
    path: "/",
    component: Main,
    exact: true,
  },
  // {
  //   type: Route,
  //   path: "/exploratory/:folder/:id",
  //   component: Exploratory,
  //   exact: true,
  // },
  // {
  //   type: Route,
  //   path: "/home",
  //   component: Main,
  //   exact: true,
  // },
  {
    type: Route,
    path: "/welcome",
    component: Launch,
    exact: true,
  },
  {
    type: Route,
    path: "/start",
    component: CreateAccount,
    exact: true,
  },
  {
    type: Route,
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    type: Route,
    path: "/signup",
    component: CreateAccount,
    exact: true,
  },
  // {
  //   type: Route,
  //   path: "/dashboard",
  //   component: Dashboard,
  //   exact: true,
  // },
  {
    type: Route,
    path: "/create-endpoint",
    component: CreateEndpoint,
    exact: true,
  },
  // {
  //   type: Route,
  //   path: "/password/reset",
  //   component: PasswordReset,
  //   exact: true,
  // },
];
